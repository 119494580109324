import { defineAsyncComponent } from 'vue'
export default {
  battles: defineAsyncComponent(() => import("/app/project-layers/dota-global/layouts/battles.vue")),
  default: defineAsyncComponent(() => import("/app/project-layers/dota-global/layouts/default.vue")),
  faq: defineAsyncComponent(() => import("/app/project-layers/dota-global/layouts/faq.vue")),
  "footer-pages": defineAsyncComponent(() => import("/app/project-layers/dota-global/layouts/footer-pages.vue")),
  "main-page": defineAsyncComponent(() => import("/app/project-layers/dota-global/layouts/main-page.vue")),
  profile: defineAsyncComponent(() => import("/app/project-layers/dota-global/layouts/profile.vue")),
  "provably-fair": defineAsyncComponent(() => import("/app/project-layers/dota-global/layouts/provably-fair.vue")),
  "card-form": defineAsyncComponent(() => import("/app/project-layers/dota/layouts/card-form.vue")),
  hamster: defineAsyncComponent(() => import("/app/project-layers/dota/layouts/hamster.vue")),
  payment: defineAsyncComponent(() => import("/app/project-layers/dota/layouts/payment.vue")),
  "secret-shop": defineAsyncComponent(() => import("/app/project-layers/dota/layouts/secret-shop.vue")),
  techies: defineAsyncComponent(() => import("/app/project-layers/dota/layouts/techies.vue"))
}